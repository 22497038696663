// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._-_-_-ant-src-components-ui-input-number--ui-input-number .ant-input-wrapper.ant-input-group{background:#F1F3F7;border-radius:8px;border:1px solid #ECEEF3}._-_-_-ant-src-components-ui-input-number--ui-input-number .ant-input-wrapper.ant-input-group:focus-within{box-shadow:0 0 0 1px var(--myteam-themeVariables-colorBrandFaded);border:1px solid var(--myteam-themeVariables-colorBrandFaded)}._-_-_-ant-src-components-ui-input-number--ui-input-number .ant-input-wrapper.ant-input-group:hover{border:1px solid var(--myteam-themeVariables-colorBrandFaded)}._-_-_-ant-src-components-ui-input-number--ui-input-number .ant-input-wrapper .ant-input[type='number']::-webkit-inner-spin-button,._-_-_-ant-src-components-ui-input-number--ui-input-number .ant-input-wrapper .ant-input[type='number']::-webkit-outer-spin-button{-webkit-appearance:none;margin:0}._-_-_-ant-src-components-ui-input-number--ui-input-number .ant-input-wrapper .ant-input{font-weight:400;font-size:15px;line-height:20px;border:none;background:inherit;text-align:center;padding-left:0;padding-right:0}._-_-_-ant-src-components-ui-input-number--ui-input-number .ant-input-wrapper .ant-input:focus,._-_-_-ant-src-components-ui-input-number--ui-input-number .ant-input-wrapper .ant-input:hover{box-shadow:none}._-_-_-ant-src-components-ui-input-number--ui-input-number .ant-input-wrapper .ant-input-group-addon{border:none;background:inherit}._-_-_-ant-src-components-ui-input-number--ui-input-number .ant-input-wrapper .ant-input-group-addon button:disabled{color:#ECEEF3}._-_-_-ant-src-components-ui-input-number--ui-input-number .ant-input-wrapper .ant-input-group-addon:first-child{padding-left:12px;padding-right:8px}._-_-_-ant-src-components-ui-input-number--ui-input-number .ant-input-wrapper .ant-input-group-addon:last-child{padding-left:8px;padding-right:12px}\n", ""]);
// Exports
exports.locals = {
	"ui-input-number": "_-_-_-ant-src-components-ui-input-number--ui-input-number",
	"uiInputNumber": "_-_-_-ant-src-components-ui-input-number--ui-input-number"
};
module.exports = exports;
