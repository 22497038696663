// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._-_-_-profile-frontend-src-components-gamification-add-thanks--add-thanks__header{margin:0}._-_-_-profile-frontend-src-components-gamification-add-thanks--add-thanks__content{border-top:1px solid #ECEEF3}._-_-_-profile-frontend-src-components-gamification-add-thanks--add-thanks__footer{display:flex}._-_-_-profile-frontend-src-components-gamification-add-thanks--add-thanks__footer-btn{margin-left:16px}._-_-_-profile-frontend-src-components-gamification-add-thanks--add-thanks__selected{display:flex;align-items:center;justify-content:center;width:88px;height:88px;border-radius:50%;background:#111111}._-_-_-profile-frontend-src-components-gamification-add-thanks--add-thanks__list{flex-wrap:wrap;display:inline-flex;padding:11px 11px 24px}._-_-_-profile-frontend-src-components-gamification-add-thanks--add-thanks__list-thank{margin:13px 0 0 13px}._-_-_-profile-frontend-src-components-gamification-add-thanks--add-thanks__wrapper{display:flex;flex-direction:column;padding:0 24px 24px}._-_-_-profile-frontend-src-components-gamification-add-thanks--add-thanks__wrapper-text-area{width:100%;padding:14px 16px}._-_-_-profile-frontend-src-components-gamification-add-thanks--add-thanks__input{font-weight:400;font-size:15px;line-height:20px;margin-top:4px;margin-bottom:6px}._-_-_-profile-frontend-src-components-gamification-add-thanks--add-thanks__input-label{font-weight:600;font-size:13px;line-height:14px;letter-spacing:0.1px;color:#818C99}._-_-_-profile-frontend-src-components-gamification-add-thanks--add-thanks__coins{display:flex;flex-direction:column;margin-top:16px}._-_-_-profile-frontend-src-components-gamification-add-thanks--add-thanks__coins-input{width:124px}\n", ""]);
// Exports
exports.locals = {
	"add-thanks__header": "_-_-_-profile-frontend-src-components-gamification-add-thanks--add-thanks__header",
	"addThanks__header": "_-_-_-profile-frontend-src-components-gamification-add-thanks--add-thanks__header",
	"add-thanks__content": "_-_-_-profile-frontend-src-components-gamification-add-thanks--add-thanks__content",
	"addThanks__content": "_-_-_-profile-frontend-src-components-gamification-add-thanks--add-thanks__content",
	"add-thanks__footer": "_-_-_-profile-frontend-src-components-gamification-add-thanks--add-thanks__footer",
	"addThanks__footer": "_-_-_-profile-frontend-src-components-gamification-add-thanks--add-thanks__footer",
	"add-thanks__footer-btn": "_-_-_-profile-frontend-src-components-gamification-add-thanks--add-thanks__footer-btn",
	"addThanks__footerBtn": "_-_-_-profile-frontend-src-components-gamification-add-thanks--add-thanks__footer-btn",
	"add-thanks__selected": "_-_-_-profile-frontend-src-components-gamification-add-thanks--add-thanks__selected",
	"addThanks__selected": "_-_-_-profile-frontend-src-components-gamification-add-thanks--add-thanks__selected",
	"add-thanks__list": "_-_-_-profile-frontend-src-components-gamification-add-thanks--add-thanks__list",
	"addThanks__list": "_-_-_-profile-frontend-src-components-gamification-add-thanks--add-thanks__list",
	"add-thanks__list-thank": "_-_-_-profile-frontend-src-components-gamification-add-thanks--add-thanks__list-thank",
	"addThanks__listThank": "_-_-_-profile-frontend-src-components-gamification-add-thanks--add-thanks__list-thank",
	"add-thanks__wrapper": "_-_-_-profile-frontend-src-components-gamification-add-thanks--add-thanks__wrapper",
	"addThanks__wrapper": "_-_-_-profile-frontend-src-components-gamification-add-thanks--add-thanks__wrapper",
	"add-thanks__wrapper-text-area": "_-_-_-profile-frontend-src-components-gamification-add-thanks--add-thanks__wrapper-text-area",
	"addThanks__wrapperTextArea": "_-_-_-profile-frontend-src-components-gamification-add-thanks--add-thanks__wrapper-text-area",
	"add-thanks__input": "_-_-_-profile-frontend-src-components-gamification-add-thanks--add-thanks__input",
	"addThanks__input": "_-_-_-profile-frontend-src-components-gamification-add-thanks--add-thanks__input",
	"add-thanks__input-label": "_-_-_-profile-frontend-src-components-gamification-add-thanks--add-thanks__input-label",
	"addThanks__inputLabel": "_-_-_-profile-frontend-src-components-gamification-add-thanks--add-thanks__input-label",
	"add-thanks__coins": "_-_-_-profile-frontend-src-components-gamification-add-thanks--add-thanks__coins",
	"addThanks__coins": "_-_-_-profile-frontend-src-components-gamification-add-thanks--add-thanks__coins",
	"add-thanks__coins-input": "_-_-_-profile-frontend-src-components-gamification-add-thanks--add-thanks__coins-input",
	"addThanks__coinsInput": "_-_-_-profile-frontend-src-components-gamification-add-thanks--add-thanks__coins-input"
};
module.exports = exports;
