// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._-_-_-profile-frontend-src-components-gamification-badge-post--gamification-badge-post__img{width:120px;height:120px;border-radius:50%;box-shadow:0 8px 16px rgba(46, 48, 51, 0.08)}._-_-_-profile-frontend-src-components-gamification-badge-post--gamification-badge-post__wrapper{flex:1;display:flex;align-self:center;flex-direction:column;margin-left:20px}._-_-_-profile-frontend-src-components-gamification-badge-post--gamification-badge-post__action{display:flex;align-items:center;margin-bottom:8px}._-_-_-profile-frontend-src-components-gamification-badge-post--gamification-badge-post__action-icon{color:#99A2AD}._-_-_-profile-frontend-src-components-gamification-badge-post--gamification-badge-post__action-name{font-weight:400;font-size:15px;line-height:20px;margin-left:8px}._-_-_-profile-frontend-src-components-gamification-badge-post--gamification-badge-post__desc{font-weight:400;font-size:15px;line-height:20px}\n", ""]);
// Exports
exports.locals = {
	"gamification-badge-post__img": "_-_-_-profile-frontend-src-components-gamification-badge-post--gamification-badge-post__img",
	"gamificationBadgePost__img": "_-_-_-profile-frontend-src-components-gamification-badge-post--gamification-badge-post__img",
	"gamification-badge-post__wrapper": "_-_-_-profile-frontend-src-components-gamification-badge-post--gamification-badge-post__wrapper",
	"gamificationBadgePost__wrapper": "_-_-_-profile-frontend-src-components-gamification-badge-post--gamification-badge-post__wrapper",
	"gamification-badge-post__action": "_-_-_-profile-frontend-src-components-gamification-badge-post--gamification-badge-post__action",
	"gamificationBadgePost__action": "_-_-_-profile-frontend-src-components-gamification-badge-post--gamification-badge-post__action",
	"gamification-badge-post__action-icon": "_-_-_-profile-frontend-src-components-gamification-badge-post--gamification-badge-post__action-icon",
	"gamificationBadgePost__actionIcon": "_-_-_-profile-frontend-src-components-gamification-badge-post--gamification-badge-post__action-icon",
	"gamification-badge-post__action-name": "_-_-_-profile-frontend-src-components-gamification-badge-post--gamification-badge-post__action-name",
	"gamificationBadgePost__actionName": "_-_-_-profile-frontend-src-components-gamification-badge-post--gamification-badge-post__action-name",
	"gamification-badge-post__desc": "_-_-_-profile-frontend-src-components-gamification-badge-post--gamification-badge-post__desc",
	"gamificationBadgePost__desc": "_-_-_-profile-frontend-src-components-gamification-badge-post--gamification-badge-post__desc"
};
module.exports = exports;
