// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../assets/svg/skill-tag.svg");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../../assets/svg/skill-confirm.svg");
var ___CSS_LOADER_URL_IMPORT_2___ = require("../../../assets/svg/skill-clear.svg");
var ___CSS_LOADER_URL_IMPORT_3___ = require("../../../assets/svg/skill-pro.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
// Module
exports.push([module.id, "._-_-_-profile-frontend-src-components-skills-button--skills-button{box-shadow:none;border-width:1px;background:#FFFFFF;font-weight:normal;margin:8px 0 0 8px;padding:12px 10px;border-color:#ECEEF3}._-_-_-profile-frontend-src-components-skills-button--skills-button:hover{color:inherit;border-color:#ECEEF3;background:var(--myteam-themeVariables-colorBrand08)}._-_-_-profile-frontend-src-components-skills-button--skills-button__icon{width:20px;height:20px;margin-right:8px;background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");-moz-transition:0.3s all;-webkit-transition:0.3s all}._-_-_-profile-frontend-src-components-skills-button--skills-button__icon:hover{-moz-transform:rotate(180deg);-webkit-transform:rotate(180deg)}._-_-_-profile-frontend-src-components-skills-button--skills-button__icon-confirm:hover{background:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ")}._-_-_-profile-frontend-src-components-skills-button--skills-button__icon-clear:hover{background:url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ")}._-_-_-profile-frontend-src-components-skills-button--skills-button__icon-pro{background:url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ")}._-_-_-profile-frontend-src-components-skills-button--skills-button__count{margin-left:8px;font-weight:normal}._-_-_-profile-frontend-src-components-skills-button--skills-button__approvers{display:flex;align-items:center;flex-direction:column;margin:2px 0px 6px}._-_-_-profile-frontend-src-components-skills-button--skills-button__approvers-avatar{margin:4px}._-_-_-profile-frontend-src-components-skills-button--skills-button__approvers-title{color:#FFFFFF;margin-bottom:4px}._-_-_-profile-frontend-src-components-skills-button--skills-button__approvers-content{display:flex;align-self:center}._-_-_-profile-frontend-src-components-skills-button--skills-button__approvers-content-icon{width:32px;height:32px;padding:0;margin:4px;border:none;border-radius:50%;color:#FFFFFF;background:#99A2AD}\n", ""]);
// Exports
exports.locals = {
	"skills-button": "_-_-_-profile-frontend-src-components-skills-button--skills-button",
	"skillsButton": "_-_-_-profile-frontend-src-components-skills-button--skills-button",
	"skills-button__icon": "_-_-_-profile-frontend-src-components-skills-button--skills-button__icon",
	"skillsButton__icon": "_-_-_-profile-frontend-src-components-skills-button--skills-button__icon",
	"skills-button__icon-confirm": "_-_-_-profile-frontend-src-components-skills-button--skills-button__icon-confirm",
	"skillsButton__iconConfirm": "_-_-_-profile-frontend-src-components-skills-button--skills-button__icon-confirm",
	"skills-button__icon-clear": "_-_-_-profile-frontend-src-components-skills-button--skills-button__icon-clear",
	"skillsButton__iconClear": "_-_-_-profile-frontend-src-components-skills-button--skills-button__icon-clear",
	"skills-button__icon-pro": "_-_-_-profile-frontend-src-components-skills-button--skills-button__icon-pro",
	"skillsButton__iconPro": "_-_-_-profile-frontend-src-components-skills-button--skills-button__icon-pro",
	"skills-button__count": "_-_-_-profile-frontend-src-components-skills-button--skills-button__count",
	"skillsButton__count": "_-_-_-profile-frontend-src-components-skills-button--skills-button__count",
	"skills-button__approvers": "_-_-_-profile-frontend-src-components-skills-button--skills-button__approvers",
	"skillsButton__approvers": "_-_-_-profile-frontend-src-components-skills-button--skills-button__approvers",
	"skills-button__approvers-avatar": "_-_-_-profile-frontend-src-components-skills-button--skills-button__approvers-avatar",
	"skillsButton__approversAvatar": "_-_-_-profile-frontend-src-components-skills-button--skills-button__approvers-avatar",
	"skills-button__approvers-title": "_-_-_-profile-frontend-src-components-skills-button--skills-button__approvers-title",
	"skillsButton__approversTitle": "_-_-_-profile-frontend-src-components-skills-button--skills-button__approvers-title",
	"skills-button__approvers-content": "_-_-_-profile-frontend-src-components-skills-button--skills-button__approvers-content",
	"skillsButton__approversContent": "_-_-_-profile-frontend-src-components-skills-button--skills-button__approvers-content",
	"skills-button__approvers-content-icon": "_-_-_-profile-frontend-src-components-skills-button--skills-button__approvers-content-icon",
	"skillsButton__approversContentIcon": "_-_-_-profile-frontend-src-components-skills-button--skills-button__approvers-content-icon"
};
module.exports = exports;
