// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._-_-_-profile-frontend-src-components-profile-info-confidential--info-confidential{display:flex;flex-direction:column}._-_-_-profile-frontend-src-components-profile-info-confidential--info-confidential__title{display:flex;align-items:center;margin-bottom:12px}._-_-_-profile-frontend-src-components-profile-info-confidential--info-confidential__wrapper{display:flex;flex-direction:column;align-items:flex-start}._-_-_-profile-frontend-src-components-profile-info-confidential--info-confidential__wrapper:not(:first-child){margin-top:12px}._-_-_-profile-frontend-src-components-profile-info-confidential--info-confidential__content{display:flex;flex-direction:column;align-items:flex-start}._-_-_-profile-frontend-src-components-profile-info-confidential--info-confidential__content-wrapper{display:flex;flex-wrap:wrap}._-_-_-profile-frontend-src-components-profile-info-confidential--info-confidential__content-value{font-weight:600;font-size:15px;line-height:20px;letter-spacing:0.1px;white-space:pre-wrap}._-_-_-profile-frontend-src-components-profile-info-confidential--info-confidential__tooltip{font-weight:400;font-size:15px;line-height:20px;color:#FFFFFF}._-_-_-profile-frontend-src-components-profile-info-confidential--info-confidential__tooltip-icon{transition:color 0.3s ease;cursor:pointer;margin-left:8px;color:#99A2AD}._-_-_-profile-frontend-src-components-profile-info-confidential--info-confidential__tooltip-icon:hover{color:var(--myteam-themeVariables-colorLinkBrand)}\n", ""]);
// Exports
exports.locals = {
	"info-confidential": "_-_-_-profile-frontend-src-components-profile-info-confidential--info-confidential",
	"infoConfidential": "_-_-_-profile-frontend-src-components-profile-info-confidential--info-confidential",
	"info-confidential__title": "_-_-_-profile-frontend-src-components-profile-info-confidential--info-confidential__title",
	"infoConfidential__title": "_-_-_-profile-frontend-src-components-profile-info-confidential--info-confidential__title",
	"info-confidential__wrapper": "_-_-_-profile-frontend-src-components-profile-info-confidential--info-confidential__wrapper",
	"infoConfidential__wrapper": "_-_-_-profile-frontend-src-components-profile-info-confidential--info-confidential__wrapper",
	"info-confidential__content": "_-_-_-profile-frontend-src-components-profile-info-confidential--info-confidential__content",
	"infoConfidential__content": "_-_-_-profile-frontend-src-components-profile-info-confidential--info-confidential__content",
	"info-confidential__content-wrapper": "_-_-_-profile-frontend-src-components-profile-info-confidential--info-confidential__content-wrapper",
	"infoConfidential__contentWrapper": "_-_-_-profile-frontend-src-components-profile-info-confidential--info-confidential__content-wrapper",
	"info-confidential__content-value": "_-_-_-profile-frontend-src-components-profile-info-confidential--info-confidential__content-value",
	"infoConfidential__contentValue": "_-_-_-profile-frontend-src-components-profile-info-confidential--info-confidential__content-value",
	"info-confidential__tooltip": "_-_-_-profile-frontend-src-components-profile-info-confidential--info-confidential__tooltip",
	"infoConfidential__tooltip": "_-_-_-profile-frontend-src-components-profile-info-confidential--info-confidential__tooltip",
	"info-confidential__tooltip-icon": "_-_-_-profile-frontend-src-components-profile-info-confidential--info-confidential__tooltip-icon",
	"infoConfidential__tooltipIcon": "_-_-_-profile-frontend-src-components-profile-info-confidential--info-confidential__tooltip-icon"
};
module.exports = exports;
