// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._-_-_-profile-frontend-src-components-profile-menu--profile-menu{padding:16px 0;background:#FFFFFF;border-radius:0px;min-width:373px;max-width:415px}@media screen and (min-device-width: 1800px){._-_-_-profile-frontend-src-components-profile-menu--profile-menu{min-width:386px}}._-_-_-profile-frontend-src-components-profile-menu--profile-menu__separator{display:flex;margin:16px 0;border-bottom:1px solid #ECEEF3}._-_-_-profile-frontend-src-components-profile-menu--profile-menu__item{display:flex;align-items:center}._-_-_-profile-frontend-src-components-profile-menu--profile-menu__item:hover{background:#F1F3F7}\n", ""]);
// Exports
exports.locals = {
	"profile-menu": "_-_-_-profile-frontend-src-components-profile-menu--profile-menu",
	"profileMenu": "_-_-_-profile-frontend-src-components-profile-menu--profile-menu",
	"profile-menu__separator": "_-_-_-profile-frontend-src-components-profile-menu--profile-menu__separator",
	"profileMenu__separator": "_-_-_-profile-frontend-src-components-profile-menu--profile-menu__separator",
	"profile-menu__item": "_-_-_-profile-frontend-src-components-profile-menu--profile-menu__item",
	"profileMenu__item": "_-_-_-profile-frontend-src-components-profile-menu--profile-menu__item"
};
module.exports = exports;
