// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._-_-_-profile-frontend-src-components-gamification--gamification{display:flex;flex-direction:column;background:#FFFFFF;border-radius:0px;height:auto;margin:10px;min-width:373px;max-width:415px}@media screen and (min-device-width: 1800px){._-_-_-profile-frontend-src-components-gamification--gamification{min-width:386px}}._-_-_-profile-frontend-src-components-gamification--gamification__wrapper{padding:24px 24px 8px}._-_-_-profile-frontend-src-components-gamification--gamification__separator{border-top:1px solid #EBEEF4}._-_-_-profile-frontend-src-components-gamification--gamification__items{padding:0 8px 24px;display:inline-flex;flex-wrap:wrap}._-_-_-profile-frontend-src-components-gamification--gamification__skeleton{margin:16px 0 0 16px}\n", ""]);
// Exports
exports.locals = {
	"gamification": "_-_-_-profile-frontend-src-components-gamification--gamification",
	"gamification__wrapper": "_-_-_-profile-frontend-src-components-gamification--gamification__wrapper",
	"gamification__separator": "_-_-_-profile-frontend-src-components-gamification--gamification__separator",
	"gamification__items": "_-_-_-profile-frontend-src-components-gamification--gamification__items",
	"gamification__skeleton": "_-_-_-profile-frontend-src-components-gamification--gamification__skeleton"
};
module.exports = exports;
