// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._-_-_-ant-src-components-ui-select--ui-select__text{font-weight:700;font-size:17px;line-height:20px;letter-spacing:0.1px;color:var(--myteam-themeVariables-colorLinkBrand)}._-_-_-ant-src-components-ui-select--ui-select__text .ant-select-selector{padding:0 4px !important;height:20px !important}._-_-_-ant-src-components-ui-select--ui-select__text .ant-select-arrow{right:4px}._-_-_-ant-src-components-ui-select--ui-select__text .ant-select-selection-item{color:var(--myteam-themeVariables-colorLinkBrand) !important;line-height:20px !important}._-_-_-ant-src-components-ui-select--ui-select__text .ant-select-arrow{color:var(--myteam-themeVariables-colorLinkBrand)}._-_-_-ant-src-components-ui-select--ui-select__text .ant-select-single.ant-select-show-arrow .ant-select-selection-item{padding-right:4px}._-_-_-ant-src-components-ui-select--ui-select__text-options{align-items:center;background-color:#111111}._-_-_-ant-src-components-ui-select--ui-select__text-options .ant-select-item{padding:8px 16px;color:#FFFFFF;background-color:#111111}._-_-_-ant-src-components-ui-select--ui-select__text-options .ant-select-item:hover{background-color:var(--myteam-themeVariables-colorBrand32)}._-_-_-ant-src-components-ui-select--ui-select__text-options .ant-select-item-option-selected:not(.ant-select-item-option-disabled){color:#818C99}\n", ""]);
// Exports
exports.locals = {
	"ui-select__text": "_-_-_-ant-src-components-ui-select--ui-select__text",
	"uiSelect__text": "_-_-_-ant-src-components-ui-select--ui-select__text",
	"ui-select__text-options": "_-_-_-ant-src-components-ui-select--ui-select__text-options",
	"uiSelect__textOptions": "_-_-_-ant-src-components-ui-select--ui-select__text-options"
};
module.exports = exports;
