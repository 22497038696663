// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._-_-_-ant-src-components-ui-scrollbars--ui-scrollbars__thumb{background-color:#11111160;border-radius:1px}._-_-_-ant-src-components-ui-scrollbars--ui-scrollbars__track{background-color:#1111110E}\n", ""]);
// Exports
exports.locals = {
	"ui-scrollbars__thumb": "_-_-_-ant-src-components-ui-scrollbars--ui-scrollbars__thumb",
	"uiScrollbars__thumb": "_-_-_-ant-src-components-ui-scrollbars--ui-scrollbars__thumb",
	"ui-scrollbars__track": "_-_-_-ant-src-components-ui-scrollbars--ui-scrollbars__track",
	"uiScrollbars__track": "_-_-_-ant-src-components-ui-scrollbars--ui-scrollbars__track"
};
module.exports = exports;
