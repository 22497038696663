// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".layouts-mail-common--common-layout{display:flex;flex-direction:column;min-height:100%;flex-grow:1;width:100%;margin:0 auto;overflow:hidden;min-width:1200px}.layouts-mail-common--common-layout__wrapper{display:flex;flex-direction:column}.layouts-mail-common--common-layout__header{width:100%;position:fixed;top:0;left:0;height:72px;z-index:5}.layouts-mail-common--common-layout__navbar{width:64px;height:calc(100vh - 72px);position:fixed;top:72px;left:0;z-index:4}@media screen and (min-width: 1440px){.layouts-mail-common--common-layout__navbar{width:260px}}.layouts-mail-common--common-layout__main{margin-top:72px;margin-left:64px}@media screen and (min-width: 1440px){.layouts-mail-common--common-layout__main{margin-left:260px}}.layouts-mail-common--common-layout__breadcrumb-container{background:#FFFFFF;margin-bottom:20px;width:100%}.layouts-mail-common--common-layout__breadcrumb-container:empty{margin-bottom:0}\n", ""]);
// Exports
exports.locals = {
	"common-layout": "layouts-mail-common--common-layout",
	"commonLayout": "layouts-mail-common--common-layout",
	"common-layout__wrapper": "layouts-mail-common--common-layout__wrapper",
	"commonLayout__wrapper": "layouts-mail-common--common-layout__wrapper",
	"common-layout__header": "layouts-mail-common--common-layout__header",
	"commonLayout__header": "layouts-mail-common--common-layout__header",
	"common-layout__navbar": "layouts-mail-common--common-layout__navbar",
	"commonLayout__navbar": "layouts-mail-common--common-layout__navbar",
	"common-layout__main": "layouts-mail-common--common-layout__main",
	"commonLayout__main": "layouts-mail-common--common-layout__main",
	"common-layout__breadcrumb-container": "layouts-mail-common--common-layout__breadcrumb-container",
	"commonLayout__breadcrumbContainer": "layouts-mail-common--common-layout__breadcrumb-container"
};
module.exports = exports;
