// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._-_-_-profile-frontend-src-components-contacts--profile-contacts{display:flex;flex-direction:column;background:#FFFFFF;border-radius:0px;min-width:373px;max-width:415px;height:auto;margin:8px}@media screen and (min-device-width: 1800px){._-_-_-profile-frontend-src-components-contacts--profile-contacts{min-width:386px}}._-_-_-profile-frontend-src-components-contacts--profile-contacts__header{padding:24px 24px 0}._-_-_-profile-frontend-src-components-contacts--profile-contacts__wrapper{padding:16px 0;border-bottom:1px solid #EBEEF4}._-_-_-profile-frontend-src-components-contacts--profile-contacts__links{color:#99A2AD;display:flex;padding:12px 24px 4px 24px}._-_-_-profile-frontend-src-components-contacts--profile-contacts__links-marker{height:32px;margin-right:8px;display:flex;align-items:center}._-_-_-profile-frontend-src-components-contacts--profile-contacts__links-container{display:flex;flex-wrap:wrap}._-_-_-profile-frontend-src-components-contacts--profile-contacts__loading-item{display:flex;align-items:center;justify-content:space-between;padding:10px 24px}\n", ""]);
// Exports
exports.locals = {
	"profile-contacts": "_-_-_-profile-frontend-src-components-contacts--profile-contacts",
	"profileContacts": "_-_-_-profile-frontend-src-components-contacts--profile-contacts",
	"profile-contacts__header": "_-_-_-profile-frontend-src-components-contacts--profile-contacts__header",
	"profileContacts__header": "_-_-_-profile-frontend-src-components-contacts--profile-contacts__header",
	"profile-contacts__wrapper": "_-_-_-profile-frontend-src-components-contacts--profile-contacts__wrapper",
	"profileContacts__wrapper": "_-_-_-profile-frontend-src-components-contacts--profile-contacts__wrapper",
	"profile-contacts__links": "_-_-_-profile-frontend-src-components-contacts--profile-contacts__links",
	"profileContacts__links": "_-_-_-profile-frontend-src-components-contacts--profile-contacts__links",
	"profile-contacts__links-marker": "_-_-_-profile-frontend-src-components-contacts--profile-contacts__links-marker",
	"profileContacts__linksMarker": "_-_-_-profile-frontend-src-components-contacts--profile-contacts__links-marker",
	"profile-contacts__links-container": "_-_-_-profile-frontend-src-components-contacts--profile-contacts__links-container",
	"profileContacts__linksContainer": "_-_-_-profile-frontend-src-components-contacts--profile-contacts__links-container",
	"profile-contacts__loading-item": "_-_-_-profile-frontend-src-components-contacts--profile-contacts__loading-item",
	"profileContacts__loadingItem": "_-_-_-profile-frontend-src-components-contacts--profile-contacts__loading-item"
};
module.exports = exports;
