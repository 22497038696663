// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._-_-_-profile-frontend-src-components-profile-info-references--info-references__projects__separator{color:#99A2AD;font-size:21px;vertical-align:text-bottom}._-_-_-profile-frontend-src-components-profile-info-references--info-references__projects__separator:last-child{display:none}\n", ""]);
// Exports
exports.locals = {
	"info-references__projects__separator": "_-_-_-profile-frontend-src-components-profile-info-references--info-references__projects__separator",
	"infoReferences__projects__separator": "_-_-_-profile-frontend-src-components-profile-info-references--info-references__projects__separator"
};
module.exports = exports;
