// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._-_-_-profile-frontend-src-components-profile-sidebar-header--sidebar-header{height:56px;position:relative}._-_-_-profile-frontend-src-components-profile-sidebar-header--sidebar-header__back-button{color:#99A2AD;position:absolute;left:8px;top:12px;padding:0;width:32px;height:32px}._-_-_-profile-frontend-src-components-profile-sidebar-header--sidebar-header__back-button_mobile{color:var(--myteam-themeVariables-colorBrand)}._-_-_-profile-frontend-src-components-profile-sidebar-header--sidebar-header__status{position:absolute;top:10px;right:10px;font-size:16px;line-height:20px}\n", ""]);
// Exports
exports.locals = {
	"sidebar-header": "_-_-_-profile-frontend-src-components-profile-sidebar-header--sidebar-header",
	"sidebarHeader": "_-_-_-profile-frontend-src-components-profile-sidebar-header--sidebar-header",
	"sidebar-header__back-button": "_-_-_-profile-frontend-src-components-profile-sidebar-header--sidebar-header__back-button",
	"sidebarHeader__backButton": "_-_-_-profile-frontend-src-components-profile-sidebar-header--sidebar-header__back-button",
	"sidebar-header__back-button_mobile": "_-_-_-profile-frontend-src-components-profile-sidebar-header--sidebar-header__back-button_mobile",
	"sidebarHeader__backButton_mobile": "_-_-_-profile-frontend-src-components-profile-sidebar-header--sidebar-header__back-button_mobile",
	"sidebar-header__status": "_-_-_-profile-frontend-src-components-profile-sidebar-header--sidebar-header__status",
	"sidebarHeader__status": "_-_-_-profile-frontend-src-components-profile-sidebar-header--sidebar-header__status"
};
module.exports = exports;
