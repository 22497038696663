// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._-_-_-components-frontend-src-components-profile-timeslots--profile-timeslots{display:flex;justify-content:space-between;align-items:center;padding:12px 16px;border-radius:0px;background-color:#FFFFFF}._-_-_-components-frontend-src-components-profile-timeslots--profile-timeslots__date{display:flex;margin-right:16px;align-self:flex-start}._-_-_-components-frontend-src-components-profile-timeslots--profile-timeslots__cell_solid{border-left:1px solid #ECEEF3}._-_-_-components-frontend-src-components-profile-timeslots--profile-timeslots__cell_dashed{border-left:1px dashed #ECEEF3}._-_-_-components-frontend-src-components-profile-timeslots--profile-timeslots__hour{font-weight:600;font-size:13px;line-height:14px;letter-spacing:0.1px;color:#818C99;padding-left:4px}._-_-_-components-frontend-src-components-profile-timeslots--profile-timeslots__table{flex:1;border-right:1px solid #ECEEF3;margin-right:24px;overflow:hidden}._-_-_-components-frontend-src-components-profile-timeslots--profile-timeslots__table .ant-table-thead tr th{height:22px}._-_-_-components-frontend-src-components-profile-timeslots--profile-timeslots__table .ant-table-tbody td{height:34px}._-_-_-components-frontend-src-components-profile-timeslots--profile-timeslots__table .ant-table-tbody>tr.ant-table-row:hover>td{background:#FFFFFF}\n", ""]);
// Exports
exports.locals = {
	"profile-timeslots": "_-_-_-components-frontend-src-components-profile-timeslots--profile-timeslots",
	"profileTimeslots": "_-_-_-components-frontend-src-components-profile-timeslots--profile-timeslots",
	"profile-timeslots__date": "_-_-_-components-frontend-src-components-profile-timeslots--profile-timeslots__date",
	"profileTimeslots__date": "_-_-_-components-frontend-src-components-profile-timeslots--profile-timeslots__date",
	"profile-timeslots__cell_solid": "_-_-_-components-frontend-src-components-profile-timeslots--profile-timeslots__cell_solid",
	"profileTimeslots__cell_solid": "_-_-_-components-frontend-src-components-profile-timeslots--profile-timeslots__cell_solid",
	"profile-timeslots__cell_dashed": "_-_-_-components-frontend-src-components-profile-timeslots--profile-timeslots__cell_dashed",
	"profileTimeslots__cell_dashed": "_-_-_-components-frontend-src-components-profile-timeslots--profile-timeslots__cell_dashed",
	"profile-timeslots__hour": "_-_-_-components-frontend-src-components-profile-timeslots--profile-timeslots__hour",
	"profileTimeslots__hour": "_-_-_-components-frontend-src-components-profile-timeslots--profile-timeslots__hour",
	"profile-timeslots__table": "_-_-_-components-frontend-src-components-profile-timeslots--profile-timeslots__table",
	"profileTimeslots__table": "_-_-_-components-frontend-src-components-profile-timeslots--profile-timeslots__table"
};
module.exports = exports;
