// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._-_-_-profile-frontend-src-components--profile-sidebar{flex:1;display:flex;flex-flow:column nowrap;height:100%;max-width:521px;min-width:320px;background:#FFFFFF}\n", ""]);
// Exports
exports.locals = {
	"profile-sidebar": "_-_-_-profile-frontend-src-components--profile-sidebar",
	"profileSidebar": "_-_-_-profile-frontend-src-components--profile-sidebar"
};
module.exports = exports;
