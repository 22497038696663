// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._-_-_-profile-frontend-src-components-profile-avatar-upload-modal--avatar-upload-modal__rule{font-weight:400;font-size:15px;line-height:20px;display:block;margin-bottom:4px}._-_-_-profile-frontend-src-components-profile-avatar-upload-modal--avatar-upload-modal__crop{font-weight:600;font-size:15px;line-height:20px;letter-spacing:0.1px;display:flex;padding-top:24px}._-_-_-profile-frontend-src-components-profile-avatar-upload-modal--avatar-upload-modal__crop>*{flex:1}._-_-_-profile-frontend-src-components-profile-avatar-upload-modal--avatar-upload-modal__crop>:last-child{margin-left:20px}._-_-_-profile-frontend-src-components-profile-avatar-upload-modal--avatar-upload-modal__crop-preview{width:100%;margin-top:16px}._-_-_-profile-frontend-src-components-profile-avatar-upload-modal--avatar-upload-modal__upload-btn{width:100%;margin-top:20px}._-_-_-profile-frontend-src-components-profile-avatar-upload-modal--avatar-upload-modal__content{padding:24px;border-top:1px solid #ECEEF3}._-_-_-profile-frontend-src-components-profile-avatar-upload-modal--avatar-upload-modal__footer{display:flex}._-_-_-profile-frontend-src-components-profile-avatar-upload-modal--avatar-upload-modal__footer>:last-child{margin-left:16px}\n", ""]);
// Exports
exports.locals = {
	"avatar-upload-modal__rule": "_-_-_-profile-frontend-src-components-profile-avatar-upload-modal--avatar-upload-modal__rule",
	"avatarUploadModal__rule": "_-_-_-profile-frontend-src-components-profile-avatar-upload-modal--avatar-upload-modal__rule",
	"avatar-upload-modal__crop": "_-_-_-profile-frontend-src-components-profile-avatar-upload-modal--avatar-upload-modal__crop",
	"avatarUploadModal__crop": "_-_-_-profile-frontend-src-components-profile-avatar-upload-modal--avatar-upload-modal__crop",
	"avatar-upload-modal__crop-preview": "_-_-_-profile-frontend-src-components-profile-avatar-upload-modal--avatar-upload-modal__crop-preview",
	"avatarUploadModal__cropPreview": "_-_-_-profile-frontend-src-components-profile-avatar-upload-modal--avatar-upload-modal__crop-preview",
	"avatar-upload-modal__upload-btn": "_-_-_-profile-frontend-src-components-profile-avatar-upload-modal--avatar-upload-modal__upload-btn",
	"avatarUploadModal__uploadBtn": "_-_-_-profile-frontend-src-components-profile-avatar-upload-modal--avatar-upload-modal__upload-btn",
	"avatar-upload-modal__content": "_-_-_-profile-frontend-src-components-profile-avatar-upload-modal--avatar-upload-modal__content",
	"avatarUploadModal__content": "_-_-_-profile-frontend-src-components-profile-avatar-upload-modal--avatar-upload-modal__content",
	"avatar-upload-modal__footer": "_-_-_-profile-frontend-src-components-profile-avatar-upload-modal--avatar-upload-modal__footer",
	"avatarUploadModal__footer": "_-_-_-profile-frontend-src-components-profile-avatar-upload-modal--avatar-upload-modal__footer"
};
module.exports = exports;
