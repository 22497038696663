// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._-_-_-profile-frontend-src-components-profile-sidebar-info--profile-sidebar-info{display:block}._-_-_-profile-frontend-src-components-profile-sidebar-info--profile-sidebar-info__separator{margin:16px 0 16px 0;border-bottom:1px solid #ECEEF3}._-_-_-profile-frontend-src-components-profile-sidebar-info--profile-sidebar-info__skeleton-wrapper{display:flex;flex-direction:column;margin-bottom:20px}._-_-_-profile-frontend-src-components-profile-sidebar-info--profile-sidebar-info__skeleton-wrapper_full-name{display:flex;flex-direction:column;align-items:center}._-_-_-profile-frontend-src-components-profile-sidebar-info--profile-sidebar-info__action-buttons{display:flex;flex-direction:row;margin:20px 0;justify-content:center}._-_-_-profile-frontend-src-components-profile-sidebar-info--profile-sidebar-info__action-button{background-color:var(--myteam-themeVariables-colorBrand08);color:var(--myteam-themeVariables-colorBrand);width:40px;height:40px;margin:0 8px}._-_-_-profile-frontend-src-components-profile-sidebar-info--profile-sidebar-info__action-button:focus{background-color:var(--myteam-themeVariables-colorBrand08);color:var(--myteam-themeVariables-colorBrand);box-shadow:none;border:none}._-_-_-profile-frontend-src-components-profile-sidebar-info--profile-sidebar-info__action-button:hover{background-color:var(--myteam-themeVariables-colorBrand16);color:var(--myteam-themeVariables-colorBrand)}._-_-_-profile-frontend-src-components-profile-sidebar-info--profile-sidebar-info__action-button:active{background-color:var(--myteam-themeVariables-colorBrand16);color:var(--myteam-themeVariables-colorBrand);border:none}\n", ""]);
// Exports
exports.locals = {
	"profile-sidebar-info": "_-_-_-profile-frontend-src-components-profile-sidebar-info--profile-sidebar-info",
	"profileSidebarInfo": "_-_-_-profile-frontend-src-components-profile-sidebar-info--profile-sidebar-info",
	"profile-sidebar-info__separator": "_-_-_-profile-frontend-src-components-profile-sidebar-info--profile-sidebar-info__separator",
	"profileSidebarInfo__separator": "_-_-_-profile-frontend-src-components-profile-sidebar-info--profile-sidebar-info__separator",
	"profile-sidebar-info__skeleton-wrapper": "_-_-_-profile-frontend-src-components-profile-sidebar-info--profile-sidebar-info__skeleton-wrapper",
	"profileSidebarInfo__skeletonWrapper": "_-_-_-profile-frontend-src-components-profile-sidebar-info--profile-sidebar-info__skeleton-wrapper",
	"profile-sidebar-info__skeleton-wrapper_full-name": "_-_-_-profile-frontend-src-components-profile-sidebar-info--profile-sidebar-info__skeleton-wrapper_full-name",
	"profileSidebarInfo__skeletonWrapper_fullName": "_-_-_-profile-frontend-src-components-profile-sidebar-info--profile-sidebar-info__skeleton-wrapper_full-name",
	"profile-sidebar-info__action-buttons": "_-_-_-profile-frontend-src-components-profile-sidebar-info--profile-sidebar-info__action-buttons",
	"profileSidebarInfo__actionButtons": "_-_-_-profile-frontend-src-components-profile-sidebar-info--profile-sidebar-info__action-buttons",
	"profile-sidebar-info__action-button": "_-_-_-profile-frontend-src-components-profile-sidebar-info--profile-sidebar-info__action-button",
	"profileSidebarInfo__actionButton": "_-_-_-profile-frontend-src-components-profile-sidebar-info--profile-sidebar-info__action-button"
};
module.exports = exports;
