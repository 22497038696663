// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._-_-_-ant-src-components-ui-button-overlay--ui-button-overlay{width:100%;display:block;position:relative}._-_-_-ant-src-components-ui-button-overlay--ui-button-overlay:focus{box-shadow:none}._-_-_-ant-src-components-ui-button-overlay--ui-button-overlay__visible_hover:hover>*{opacity:1}._-_-_-ant-src-components-ui-button-overlay--ui-button-overlay__wrapper{left:0;bottom:0;position:absolute;width:100%;height:48px;display:flex;align-items:center;justify-content:center;color:#FFFFFF;background:rgba(31, 32, 34, 0.64);border-radius:0 0 8px 8px;transition:opacity 0.3s ease-out}._-_-_-ant-src-components-ui-button-overlay--ui-button-overlay__wrapper-visible_hover{opacity:0}._-_-_-ant-src-components-ui-button-overlay--ui-button-overlay__wrapper-label{font-weight:600;font-size:15px;line-height:20px;letter-spacing:0.1px;margin-left:10px;color:inherit}\n", ""]);
// Exports
exports.locals = {
	"ui-button-overlay": "_-_-_-ant-src-components-ui-button-overlay--ui-button-overlay",
	"uiButtonOverlay": "_-_-_-ant-src-components-ui-button-overlay--ui-button-overlay",
	"ui-button-overlay__visible_hover": "_-_-_-ant-src-components-ui-button-overlay--ui-button-overlay__visible_hover",
	"uiButtonOverlay__visible_hover": "_-_-_-ant-src-components-ui-button-overlay--ui-button-overlay__visible_hover",
	"ui-button-overlay__wrapper": "_-_-_-ant-src-components-ui-button-overlay--ui-button-overlay__wrapper",
	"uiButtonOverlay__wrapper": "_-_-_-ant-src-components-ui-button-overlay--ui-button-overlay__wrapper",
	"ui-button-overlay__wrapper-visible_hover": "_-_-_-ant-src-components-ui-button-overlay--ui-button-overlay__wrapper-visible_hover",
	"uiButtonOverlay__wrapperVisible_hover": "_-_-_-ant-src-components-ui-button-overlay--ui-button-overlay__wrapper-visible_hover",
	"ui-button-overlay__wrapper-label": "_-_-_-ant-src-components-ui-button-overlay--ui-button-overlay__wrapper-label",
	"uiButtonOverlay__wrapperLabel": "_-_-_-ant-src-components-ui-button-overlay--ui-button-overlay__wrapper-label"
};
module.exports = exports;
