// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._-_-_-profile-frontend-src-components-profile-sidebar-info--info-contacts__loading-item{display:flex;flex-direction:column}._-_-_-profile-frontend-src-components-profile-sidebar-info--info-contacts__separator{margin:16px 0 16px 0;border-bottom:1px solid #ECEEF3}\n", ""]);
// Exports
exports.locals = {
	"info-contacts__loading-item": "_-_-_-profile-frontend-src-components-profile-sidebar-info--info-contacts__loading-item",
	"infoContacts__loadingItem": "_-_-_-profile-frontend-src-components-profile-sidebar-info--info-contacts__loading-item",
	"info-contacts__separator": "_-_-_-profile-frontend-src-components-profile-sidebar-info--info-contacts__separator",
	"infoContacts__separator": "_-_-_-profile-frontend-src-components-profile-sidebar-info--info-contacts__separator"
};
module.exports = exports;
