// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../../assets/images/pattern-achieve.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "._-_-_-profile-frontend-src-components-gamification-list-item--list-item{display:flex;flex-direction:column}._-_-_-profile-frontend-src-components-gamification-list-item--list-item__body{padding:0 24px 24px;border-bottom:1px solid #EBEEF4}._-_-_-profile-frontend-src-components-gamification-list-item--list-item__body-pattern{flex:1;display:flex;padding:20px;border-radius:4px;position:relative;background:var(--myteam-themeVariables-colorBrand08) url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ")}._-_-_-profile-frontend-src-components-gamification-list-item--list-item__body-wrapper{align-items:center;justify-content:center;flex-direction:column}\n", ""]);
// Exports
exports.locals = {
	"list-item": "_-_-_-profile-frontend-src-components-gamification-list-item--list-item",
	"listItem": "_-_-_-profile-frontend-src-components-gamification-list-item--list-item",
	"list-item__body": "_-_-_-profile-frontend-src-components-gamification-list-item--list-item__body",
	"listItem__body": "_-_-_-profile-frontend-src-components-gamification-list-item--list-item__body",
	"list-item__body-pattern": "_-_-_-profile-frontend-src-components-gamification-list-item--list-item__body-pattern",
	"listItem__bodyPattern": "_-_-_-profile-frontend-src-components-gamification-list-item--list-item__body-pattern",
	"list-item__body-wrapper": "_-_-_-profile-frontend-src-components-gamification-list-item--list-item__body-wrapper",
	"listItem__bodyWrapper": "_-_-_-profile-frontend-src-components-gamification-list-item--list-item__body-wrapper"
};
module.exports = exports;
