// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._-_-_-profile-frontend-src-components-profile-transactions-history-transaction--transaction{display:flex;margin-bottom:20px}._-_-_-profile-frontend-src-components-profile-transactions-history-transaction--transaction__icon{color:var(--myteam-themeVariables-colorBrand);margin-right:8px}._-_-_-profile-frontend-src-components-profile-transactions-history-transaction--transaction__icon_secondary{color:#99A2AD}._-_-_-profile-frontend-src-components-profile-transactions-history-transaction--transaction__title{margin:0 4px}._-_-_-profile-frontend-src-components-profile-transactions-history-transaction--transaction__date{margin-left:auto}\n", ""]);
// Exports
exports.locals = {
	"transaction": "_-_-_-profile-frontend-src-components-profile-transactions-history-transaction--transaction",
	"transaction__icon": "_-_-_-profile-frontend-src-components-profile-transactions-history-transaction--transaction__icon",
	"transaction__icon_secondary": "_-_-_-profile-frontend-src-components-profile-transactions-history-transaction--transaction__icon_secondary",
	"transaction__title": "_-_-_-profile-frontend-src-components-profile-transactions-history-transaction--transaction__title",
	"transaction__date": "_-_-_-profile-frontend-src-components-profile-transactions-history-transaction--transaction__date"
};
module.exports = exports;
